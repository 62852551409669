import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'sw-ui';

import { dateWithoutCurrentYear } from '../../../../bi/utils/formatDate';
import MoneyFormat from '../../../../bi/utils/money';
import { TRIPSLABELS } from '../../../../bi/constants/trips';

import LinkToNewTab from '../../../../components/LinkToNewTab';

import {
  SERVICETYPE, SERVICE_TYPE_BY_ID, SERVICETYPEICON,
} from '../../../../bi/constants/serviceType';

import MICE_IMG from '../../../../app/styles/img/calendar_mice.svg';
import IMG_BUS from '../../../../app/styles/img/bus_icon.png';

import styles from '../../styles/trip.module.scss';

const Trip = ({
  item,
  companyId,
  accountId,
  isEmployeeTrip,
  onHandleSelectedTripsId,
  selectedTripsId,
  getTripMove,
}) => {
  const colClass = styles.col_wrap;
  const serviceHtml = item.Services.map((service, key) => {
    const serviceKey = typeof service === 'string' ? service : SERVICE_TYPE_BY_ID[service];
    switch (serviceKey) {
      case SERVICETYPE.AEROEXPRESS:
        return <div key={ key } className='smartway-aeroexpress-temp' />;
      case SERVICETYPE.VIP_HALL:
        return <div key={ key } className='smartway-vip-hall' />;
      case SERVICETYPE.AIR_ADDITIONAL_SERVICE:
        return <div key={ key } className={ `airplane_ticket ${styles['icon-services']}` } />;
      case SERVICETYPE.EVENT:
        return <img key={ key } className={ styles.mice } src={ MICE_IMG } alt='MICE' />;
      case SERVICETYPE.BUS:
        return <img key={ key } className={ styles.bus } src={ IMG_BUS } alt='BUS' />;

      default:
        return (
          <i
            className={ `material-icons ${styles['icon-services']}` }
            key={ key }
          >
            { SERVICETYPEICON[serviceKey] }
          </i>
        );
    }
  });

  const employeesHtml = item.Employees.map((employee, ind) => (
    <div key={ ind }>
      { employee }
    </div>
  ));

  const statusCol = !isEmployeeTrip
    && (
      <div className={ colClass }>
        { item.StatusRu }
      </div>
    );

  const renderCheckbox = () => {
    if (!getTripMove()) {
      return null;
    }

    const { Services } = item;

    if (Services.includes(SERVICETYPE.TAXI)) {
      return <div className={ styles.checkbox_wrap } />;
    }

    return (
      <Checkbox
        className={ styles.checkbox_wrap }
        onChange={ () => onHandleSelectedTripsId(item.Id) }
        value={ selectedTripsId.includes(item.Id) }
      />
    );
  };

  const renderTripId = (item, colClass) => (
    <div className={ colClass }>
      { item.Id }
      { item.Labels.includes('CardPayment') && (
        <span> ({ TRIPSLABELS.CARD_PAYMENT }) </span>
      ) }
    </div>
  );

  return (
    <LinkToNewTab to={ `/account/${accountId}/company/${companyId}/trip/${item.Id}` }>
      <div className={ `${styles.row} ${styles.click}` }>
        { renderCheckbox() }
        { renderTripId(item, colClass) }
        <div className={ colClass }>
          { dateWithoutCurrentYear(item.CheckInDate) }
        </div>
        <div className={ colClass }>
          { dateWithoutCurrentYear(item.CheckOutDate) }
        </div>
        <div className={ colClass }>
          { item.Name }
        </div>
        <div className={ `${colClass} ${styles['data-services']}` }>
          { serviceHtml }
        </div>
        <div className={ colClass }>
          { employeesHtml }
        </div>
        <div className={ colClass }>
          { item.Price ? MoneyFormat.symbolWithMoneyWithDecimal(item.Price) : 0 }
        </div>
        { statusCol }
      </div>
    </LinkToNewTab>
  );
};

Trip.propTypes = {
  item: PropTypes.object,
  accountId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  isEmployeeTrip: PropTypes.bool,
  onHandleSelectedTripsId: PropTypes.func,
  selectedTripsId: PropTypes.array,
  getTripMove: PropTypes.func,
};

Trip.defaultProps = {
  item: {},
  isEmployeeTrip: false,
  onHandleSelectedTripsId: () => {},
  selectedTripsId: [],
  getTripMove: () => false,
};

export default Trip;
